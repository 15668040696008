@import "../../assets/style/_var.scss";

.participantslist {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__container {
        width: 1280px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 140px;

        &-title {
            color: $white;
            font-family: $baseFont;
            font-weight: 700;
            font-size: 70px;
            color: $black;
            margin: 175px 0 50px 0;

            @media (max-width: 430px) {
                font-size: 34px;
                margin-top: 70px;
            }
        }

        &-text {
            color: $black;
            font-family: $baseFont;
            font-weight: 500;
            font-size: 19px;
            margin-bottom: 100px;
            text-align: center;

            @media (max-width: 430px) {
                font-size: 18px;
                position: absolute;
                top: 120px;
                left: 20px;
              }
        }

        &-button {
            background-color: $black;
            font-family: $baseFont;
            font-weight: 600;
            font-size: 16px;
            color: $white;
            border-radius: 16px;
            border: none;
            width: 300px;
            height: 50px;
            margin: 0 auto;
            cursor: pointer;

            &:disabled {
                background-color: #656565;
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}

.participantsTable {
    width: 100%;

    @media (max-width: 430px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    
}

.ant-table {
    background-color: rgba(255, 255, 255, 0.5) !important;
    border-radius: 16px !important;
}

.ant-table-thead > tr > th {
    background-color: rgba(255, 255, 255, 0.5) !important;
    border-bottom-color: #c4c4c4 !important;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: rgba(255, 255, 255, 0.5) !important;
}
.ant-table th, .ant-table td {
    border-color: yellow !important; 
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
