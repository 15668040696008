@import "../../../assets/style/_var.scss";

.chalCard {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
  cursor: pointer;

  &__price {
    font-weight: 500;
    font-family: $baseFont;
    color: $white;
    margin-bottom: 24px;
    font-size: 50px;
  }

  &__type {
    font-weight: 600;
    font-family: $baseFont;
    color: $white;
    margin-bottom: 22px;
    font-size: 27px;
  }

  &__person {
    padding-right: 34px;
    font-family: $baseFont;
    color: $white;
    font-size: 20px;
    @media (max-width: 430px) {
      font-size: 18px;
    }
  }
}