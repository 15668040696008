@import "../../assets/style/_var.scss";

.about {
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  padding: 140px 0;

  &__container {
    width: 1280px;
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 430px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &-left {
      display: flex;
      width: 50%;

      @media (max-width: 430px) {
        width: 100%;
        justify-content: center;
      }
    }

    &-right {
      display: flex;
      width: 50%;
      flex-direction: column;

      @media (max-width: 430px) {
        width: 90%;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }

  &__img {
    width: 530px;
    height: 675px;
    border-radius: 16px;
    object-position: left;
    object-fit: cover;
    background-repeat: no-repeat;

    @media (max-width: 430px) {
      width: 350px;
      height: 500px;
    }
  }

  &__title {
    font-family: $baseFont;
    font-weight: 700;
    font-size: 80px;
    color: $black;
    margin-bottom: 40px;
    margin-top: 0;

    @media (max-width: 430px) {
      font-size: 34px;
      margin-bottom: 24px;
      text-align: center;
    }
  }

  &__info {
    font-family: $baseFont;
    font-weight: 600;
    font-size: 27px;
    color: $black;
    margin-bottom: 27px;
    margin-top: 0;

    @media (max-width: 430px) {
      font-size: 20px;
      margin-bottom: 16px;
      text-align: center;
    }
  }

  &__text {
    font-family: $baseFont;
    font-weight: 500;
    font-size: 19px;
    color: $black;
    margin: 0;
    line-height: 1.9;
    letter-spacing: 0.3px;
    text-align: justify;

    @media (max-width: 430px) {
      padding-top: 20px;
      font-size: 18px;
      text-align: center;
    }
  }
}