@import "../../assets/style/_var.scss";

.modal-success {
    background-color: rgba(255, 250, 148, 0.9);
}

.modal-failure {
    background-color: #ff7961;
}

.modal {
    width: 500px;
    border: #656565 1px solid;
    border-radius: 16px;
    position: fixed; // Add this to position your modal
    top: 50%; // Center vertically
    left: 50%; // Center horizontally
    transform: translate(-50%, -50%); // Adjust for the modal's own size

    @media (max-width: 430px) {
        width: auto; // Use auto width
        max-width: calc(100% - 30px); // Set maximum width with 15px gap on each side
        margin: 0 15px; // 15px margin on left and right sides
        top: 100px;
        left: 15px; // Align the left edge of the modal 15px from the left edge of the screen
        right: 15px; // Align the right edge of the modal 15px from the right edge of the screen
        transform: none;
    }


    &__container {
        width: 100%;
        max-width: 1280px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;

        &-title {
            font-family: $baseFont;
            font-weight: 700;
            font-size: 24px; // Adjusted font size
            color: $black;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        &-subheader {
            font-family: $baseFont;
            font-weight: 500;
            font-size: 18px;
            color: $black;
            margin-bottom: 20px;
        }
    }

    &__close-btn {
        background-color: $black;
        font-family: $baseFont;
        font-weight: 600;
        font-size: 16px;
        color: $white;
        border-radius: 16px;
        border: none;
        width: 150px;
        height: 40px;
        margin-top: 40px;
        cursor: pointer;
        align-self: center; // To center the button

        &:hover {
            background-color: darken($black, 10%);
        }

        &:disabled {
            background-color: #656565;
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

}