@import "../../assets/style/_var.scss";

.banner {
  height: 100vh;
  width: 100%;

  &__wrapper {
    background-image: linear-gradient(80deg,
      rgba(255, 255, 255, 0.1),
      rgba(0, 0, 0, 0.7)),
      url("../../assets/images/banner.webp");
    transform: scale(1.25, 1.25);
    background-size: cover;
    background-position: center 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;

    width: 100%;

    &-herobanner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 12.8rem;
      position: relative;
      top: 20%;
      z-index: 1;
      color: #fff;
      text-shadow: 10px 5px 5px rgba(0, 0, 0, 0.35);
      text-align: left;

      @media (max-width: 430px) {
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        padding: 0 30px;
        top: 18%;
        box-sizing: border-box;
      }
    }

    &-title {
      font-family: $baseFont;
      color: $white;
      font-size: 6.6rem;
      font-weight: 700;
      z-index: 2;
      margin-bottom: 34px;

      @media (max-width: 430px) {
        font-size: 54px;
        margin-bottom: 20px;
      }
    }

    &-date {
      font-family: $baseFont;
      color: $white;
      margin-bottom: 28px;
      font-size: 28px;
      font-weight: 700;

      @media (max-width: 430px) {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 11px;
        font-size: 21px;
      }
    }

    &-registration {
      font-family: $baseFont;
      font-size: 30px;
      margin-top: 30px;
      color: #fbf128;
      text-decoration: underline;
      font-weight: 700;

      @media (max-width: 430px) {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 18px;
        margin-top: 24px;
      }
    }

    &-address {
      font-weight: 390px;
      font-weight: 20px;
      font-family: $baseFont;
      color: $white;

      @media (max-width: 430px) {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        max-width: 200px;
      }
    }

    &-picture {
      transform: rotate(-15deg);
      left: 60rem;
      margin-top: −180px !important;
      top: -27rem !important;
      position: absolute;
      z-index: 1;

      img {
        width: 235px !important;
      }

      @media (max-width: 800px) {
        display: none;
      }
    }
  }
}

.fadeInUp {
  z-index: 2;
}