@import "../../assets/style/_var.scss";

.imprint {
    width: 90%;

    padding: 140px 40px;
    display: flex;
    flex-direction: column;

    &__container {
        display: flex;
        flex-direction: row;

        @media (max-width: 430px) {
            flex-direction: column-reverse;
        }

        &-left {
            width: 35%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 160px;

            @media (max-width: 700px) {
                width: 100%;
                display: none;
            }
        }

        &-middle {
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 430px) {
                width: 30%;
            }

            &-mobile {
                display: none;

                @media (max-width: 430px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 30%;
                }
            }
        }

        &-right {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &__title {
        font-family: $baseFont;
        font-weight: 700;
        font-size: 80px;
        color: $black;
        margin-bottom: 27px;
        margin-top: 0;

        @media (max-width: 430px) {
            font-size: 34px;
            margin-bottom: 24px;
            text-align: center;
        }
    }

    &__text {
        font-family: $baseFont;
        font-weight: 600;
        font-size: 27px;
        color: $black;

        @media (max-width: 430px) {
            font-size: 16px;
            margin-bottom: 16px;
            text-align: center;
        }
    }

    &__counter {
        display: flex;
        flex-direction: row;
        margin: 110px 0;

        &-quantity {
            text-align: center;
            font-family: $baseFont;
            font-weight: 700;
            font-size: 108px;
            color: $black;

            @media (max-width: 430px) {
                font-size: 50px;
            }

            &-null {
                text-align: center;
                font-family: $baseFont;
                font-weight: 700;
                font-size: 108px;
                color: $black;

                @media (max-width: 430px) {
                    font-size: 50px;
                }
            }
        }

        @keyframes pulsate {
            0% {
                color: red;
            }

            50% {
                color: black;
            }

            100% {
                color: red;
            }
        }

        .pulsate {
            animation: pulsate 3s ease-in-out infinite;
        }

        &-country {
            font-family: $baseFont;
            font-weight: 700;
            font-size: 22px;
            color: $black;

            @media (max-width: 430px) {
                font-size: 16px;
                text-align: center;
            }
        }
    }

    &__button {
        margin: 0 auto;
        background-color: $black;
        color: $white;
        font-family: $baseFont;
        font-weight: 700;
        font-size: 20px;
        color: $white;
        width: 350px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 12px;
        cursor: pointer;

        @media (max-width: 430px) {
            width: 300px;
            height: 50px;
        }
    }
}