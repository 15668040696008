@import "../../../assets/style/_var.scss";

.listInfo {
  display: flex;
  flex-direction: column;
  width: 231px;
  box-sizing: border-box;
  margin-bottom: 50px;
  &__icon {
    margin-bottom: 5px;
    svg {
      width: 16px;
      height: 20px;
      @media (max-width: 430px) {
        width: 14px;
        height: 18px;
        margin-top: 10px;
      }
    }
  }
  &__title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    font-family: $baseFont;
    color: $black;
    margin-top: 0;
    @media (max-width: 430px) {
      font-size: 18px;
    }
  }
  &__info{
    font-size: 14px;
    font-weight: 400;
    font-family: $baseFont;
    color: $black;
    width: 100%;
    display: flex;
    @media (max-width: 430px) {
      font-size: 18px;
    }
  }
}
