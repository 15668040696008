.mobileDetails {
  @media (max-width: 699px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    .listInfo {
      align-items: center;
      margin: 0 auto;
      margin-top: 16px;
    }
    .listInfo__info {
      justify-content: center;
      text-align: center;
    }
    &__wrapp {
      display: flex;
      flex-direction: column;
      &-img{
        width: 100%;
        height: auto;
        object-fit: cover;
        margin-top: 10px;
      }
    }
    &__route {
      display: flex;
      margin-top: 20px;
      position: relative;
      margin-top: 220px;
      justify-content: center;
      &-linne {
        position: absolute;
        top: 0;
        transform: rotate(97deg);
        margin-left: -40px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      &-border {
        svg {
          width: 100%;
          height: 100%;
        }
        transform: rotate(97deg);
        position: absolute;
        margin-top: 1px;
        left: 0;
        margin-left: -40px;
      }
      &-startCard {
        display: flex;
        width: 100%;
        padding-left: 76px;
        margin-top: -121px;
        z-index: 2;
      }
      &-midCard {
        display: flex;
        width: 100%;
        padding-left: 86px;
        margin-top: 179px;
        z-index: 3;
      }
      &-finishCard {
        display: flex;
        width: 100%;
       margin-top: 30px;
      }
    }
    &__map {
      position: relative;
      margin-top: 60px;
      display: flex;

      &-grey {
        position: absolute;
        top: 0;
        transform: rotate(81deg);
        width: 40%;
        z-index: 2;
        margin-top: 82px;
        margin-left: 150px;
        svg {
          width: 320px;
          height: 240px;
        }
      }
      &-bord {
        width: 40%;
        transform: rotate(81deg);
        position: absolute;
        margin-left: 150px;
        margin-top: 82px;
        svg {
          width: 320px;
          height: 240px;
        }
      }
    }
  }
  @media (min-width: 700px) {
    display: none;
  }
}
