@import "../../assets/style/_var.scss";

.registration {
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;
    max-width: 1280px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 140px;
    @media (max-width: 430px) {
      margin-top: 50px;
    }

    &-title {
      font-family: $baseFont;
      font-weight: 700;
      font-size: 50px;
      color: $black;
      margin-bottom: 40px;
      margin-top: 0;

      @media (max-width: 430px) {
        font-size: 34px;
        margin-top: 50px;
      }
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 800px;
    padding-bottom: 200px;

    &-discipline {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 40px;

      @media (max-width: 430px) {
        flex-direction: column;
        align-items: center;
        width: 95%;
        align-items: center;
        margin: 0 auto;
      }
    }

    &-name {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      @media (max-width: 430px) {
        flex-direction: column;
        align-items: center;
        width: 95%;
        align-items: center;
        margin: 0 auto;
      }
    }

    &-wrapp {
      display: flex;
      flex-direction: column;
      width: 350px;
      justify-content: space-between;

      @media (max-width: 430px) {
        width: 98%;
        margin: 0 auto;
      }
    }


    &-input {
      background-color: #f7f6f6;
      border: none;
      
      border-radius: 16px;
      height: 50px;
      padding: 0 10px;
      font-family: $baseFont;
      font-weight: 500;
      font-size: 16px;
      color: $black;
      margin-bottom: 24px;
    }

    &-radio {
      display: flex;
      flex-direction: row;
      width: 350px;
      justify-content: space-between;
      background-color: #f7f6f6;
      border: none;
      border-radius: 16px;
      height: 50px;
      font-family: $baseFont;
      font-weight: 500;
      font-size: 16px;
      color: $black;
      margin-bottom: 24px;
    }

    &-radio-label {
      margin: 14px;
    }

    &-date {
      background-color: #f7f6f6;
      border: none;
      border-radius: 16px;
      height: 40px;
      padding: 0 10px;
      font-family: $baseFont;
      font-weight: 500;
      font-size: 16px;
      color: $black;
      margin-bottom: 16px;
    
      @media (max-width: 430px) {
        align-items: center;
        width: 88%;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 16px;
        height: 50px;
        -webkit-appearance: none!important; /* Remove default Safari styles */
        appearance: none!important;
      }
    }

    &-title {
      font-family: $baseFont;
      font-weight: 600;
      font-size: 18px;
      color: $black;
      margin-bottom: 6px;

      @media (max-width: 430px) {
        margin-left: 10px;
        &-data {
          margin-left: 20px;
          font-family: $baseFont;
          font-weight: 600;
          font-size: 18px;
          color: $black;
          margin-bottom: 6px;
        }
      }
    }

    &-label {
      font-family: $baseFont;
      font-weight: 500;
      font-size: 16px;
      color: $black;
      margin-bottom:6px;

      @media (max-width: 430px) {
        margin-left: 10px;
        &-data {
          margin-left: 20px;
          font-family: $baseFont;
          font-weight: 500;
          font-size: 16px;
          color: $black;
          margin-bottom: 6px;
        }
       
      }
      &-data {
       
        font-family: $baseFont;
        font-weight: 500;
        font-size: 16px;
        color: $black;
        margin-bottom: 12px;
      }
    }

    &-addwrapp {
      display: flex;
      flex-direction: column;
      border-top: 2px solid #656565;
      padding: 24px 0;
    }

    &-policy {
      margin-bottom: 30px;
      @media (max-width: 430px) {
        margin-top: 20px;
        align-items: center;
        width: 90%;
        align-items: center;
        margin: 0 auto;
      }
    }

    &-policyLabel {
      font-family: $baseFont;
      font-weight: 500;
      padding-left: 10px;
    }

    &-removeBtn {
      background-color: #4d4c4c;
      font-family: $baseFont;
      font-weight: 600;
      font-size: 16px;
      color: $white;
      border-radius: 16px;
      border: none;
      width: 300px;
      height: 50px;
      margin: 0 auto;
      cursor: pointer;
    }

    &-addBtn {
      background-color: #107010;
      font-family: $baseFont;
      font-weight: 600;
      font-size: 16px;
      color: $white;
      border-radius: 16px;
      border: none;
      width: 300px;
      height: 50px;
      margin: 0 auto;
      cursor: pointer;
      margin-bottom: 24px;
    }

    &-btn {
      background-color: $black;
      font-family: $baseFont;
      font-weight: 600;
      font-size: 18px;
      color: $white;
      border-radius: 16px;
      border: none;
      width: 300px;
      height: 50px;
      margin: 0 auto;
      margin-top: 20px;
      cursor: pointer;

      &:disabled {
        background-color: #656565;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
