@import "../../assets/style/_var.scss";

// Define breakpoints as variables for easier maintenance
$bp-mobile: 430px;
$bp-content: 575px;

.challenge {
  background-color: #222;
  padding: 140px 0;

  &__container, &__wrapp {
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
    position: relative;
    z-index: 5;
  }

  &__container {
    width: 1280px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: $bp-mobile) {
      width: 100%;
    }

    &-title, &-info {
      font-family: $baseFont;
      color: $white;
      margin-bottom: 50px;
    }

    &-title {
      font-weight: 700;
      font-size: 80px;
      margin-top: 0;

      @media (max-width: $bp-mobile) {
        font-size: 40px;
        text-align: center;
      }
    }

    &-info {
      font-weight: 500;
      font-size: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      p {
        margin-left: 16px;
      }

      @media (max-width: $bp-mobile) {
        flex-direction: column;
        font-size: 20px;
      }
    }

    // Combine similar styles for calendar and location
    &-calendar, &-location {
      display: flex;
      align-items: center;

      @media (max-width: $bp-mobile) {
        justify-content: center;
        padding: 0 24px;
        margin-top: 20px; // Only applies to &-location, but won't harm &-calendar
      }

      &-icon {
        @media (max-width: $bp-mobile) {
          width: 30px;
          height: 30px;
        }

        path {
          fill: $white;
        }
      }
    }
  }

  &__wrapp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $bp-content) {
      flex-direction: column;
    }
  }

  // Combine similar background styles
  &__single, &__team, &__mountain, &__run {
    background-size: cover;
    background-position: center 100%;
    height: 530px;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    width: 400px;
    border-radius: 24px;
    margin-bottom: 20px;
    mix-blend-mode: multiply;
    justify-content: center;

    @media (max-width: $bp-mobile) {
      width: 350px;
    }
  }

  // Specific background images and gradients
  &__single {
    background-image: linear-gradient(80deg, rgba(167, 221, 152, 0.1), rgba(114, 145, 105, 0.7)), url("../../assets/images/single.webp");
  }

  &__team {
    background-image: linear-gradient(80deg, rgba(167, 221, 152, 0.1), rgba(114, 145, 105, 0.7)), url("../../assets/images/team.webp");
  }

  &__mountain {
    background: linear-gradient(180deg, rgba(221, 207, 152, 0.1), rgba(253, 170, 98, 0.7)), url("../../assets/images/run.webp");
  }

  &__run {
    background: linear-gradient(180deg, rgba(167, 221, 152, 0.1), rgba(114, 145, 105, 0.7)), url("../../assets/images/cycling.webp") center/cover;
  }
}
