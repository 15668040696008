@import "../../assets/style/_var.scss";

.footer {
  background-color: $black;
  width: 100%;
  bottom: 0;
  margin-top: 100px;

  &__container {
    width: 1280px;
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;

    @media (max-width: 430px) {
      flex-direction: column;
    }
  }

  &__copyright {
    font-family: $baseFont;
    font-weight: 500;
    font-size: 16px;
    color: $white;
    width: 27%;

    @media (max-width: 430px) {
      width: 100%;
      text-align: center;
      font-size: 14px;
      max-width: 300px
    }
  }

  &__social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 8%;

    @media (max-width: 430px) {
      width: 28%;
      margin-top: 24px;
    }

    &-item {
      background-color: $white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 20%;

    @media (max-width: 430px) {
      width: 55%;
      margin-top: 24px;
    }
  }

  &__hdsports {
    margin-top: 5px;
    @media (max-width: 430px) {
      margin-top: 24px;
    }
  }

  &__green-link {
    font-weight: 600;
    color: #85d29b;
    font-family: $baseFont;
    font-size: 16px;
    position: relative;
    text-decoration: none;

    @media (max-width: 430px) {
      font-size: 12px;
    }


    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      left: 0 !important;
      background: #85d29b;
      bottom: 0;
      width: 0%;
      border-radius: 5px;
      transition: 0.25s linear;
    }

    &:hover::before,
    &:focus::before {
      width: 100%;
    }
  }
}

.orange-link {
  color: #85d29b;
  position: relative;
  text-decoration: none;
}

.orange-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0 !important;
  background: #85d29b;
  bottom: 0;
  width: 0%;
  border-radius: 5px;
  transition: 0.25s linear;
}

.orange-link:hover::before,
.orange-link:focus::before {
  width: 100%;
}