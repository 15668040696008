@import "../../assets/style/_var.scss";

.results {
  width: 100%;
  height: 600px;
  padding: 140px 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 250, 148, 0.9);

  &__header {
    text-align: center;
  }

  &__title {
    font-family: $baseFont;
    font-weight: 700;
    font-size: 70px;
    color: $black;
    margin-bottom: 27px;
    margin-top: 0;

    @media (max-width: 430px) {
      font-size: 34px;
      margin-bottom: 24px;
      text-align: center;
    }
  }

  &__text {
    font-family: $baseFont;
    font-weight: 600;
    font-size: 27px;
    color: $black;

    @media (max-width: 430px) {
      font-size: 18px;
      margin-bottom: 16px;
      text-align: center;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  &__item {
    margin-bottom: 20px;
    font-family: $baseFont;
    font-weight: 600;
    font-size: 24px;

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__button {
    margin: 50px auto 0;
    background-color: $black;
    color: $white;
    font-family: $baseFont;
    font-weight: 700;
    font-size: 20px;
    color: $white;
    width: 350px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 12px;
    cursor: pointer;

    @media (max-width: 430px) {
      width: 300px;
      height: 50px;
    }
  }
}
