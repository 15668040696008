@import "../../assets/style/_var.scss";

.details {
  background-color: rgba(255, 255, 255, 1);
  padding: 140px 0;
  &__wrapp {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 5rem;
    @media (max-width: 430px) {
      padding: 0;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    &-container {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    &-title {
      font-family: $baseFont;
      font-weight: 700;
      font-size: 82px;
      color: $black;
      margin-bottom: 34px;
      text-align: center;
      @media (max-width: 430px) {
        font-size: 38px;
        margin-bottom: 20px;
        text-align: center;
        // white-space: nowrap;
      }
    }
    &-text {
      font-family: $baseFont;
      font-size: 33px;
      color: $black;
      margin-bottom: 130px;
      text-align: center;
      font-weight: 600;
      @media (max-width: 430px) {
        font-size: 18px;
        margin-bottom: 20px;
        text-align: center;
      }
    }
    &-map {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    &-middle {
      display: flex;
      flex-direction: column;

      position: relative;
      width: 100%;
    }
    &-route {
      display: flex;
      flex-direction: column;
      width: 460px;

      &-type {
        display: flex;
        width: 100%;
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        font-family: $baseFont;
        color: $black;
        justify-content: center;
        margin-left: 20px;
      }
      &-name {
        display: flex;
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        font-family: $baseFont;
        color: $black;
        justify-content: center;
        margin-top: 20px;
      }
      &-meter {
        position: absolute;
        margin-top: 117px;
        margin-left: 150px;
        font-size: 16px;
        font-weight: 700;
        font-family: $baseFont;
        color: $black;
      }
      &-map {
        width: 100%;
        position: absolute;
        margin-top: 85px;
        max-width: 474px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-yellow {
        width: 100%;
        position: relative;
        max-width: 290px;
      }
      &-border {
        width: 100%;
        position: absolute;
        margin-top: 173px;
        max-width: 290px;
        margin-left: 2px;
      }
      &-linne {
        width: 100%;
        position: absolute;
        margin-top: 166px;
        max-width: 290px;
      }
    }
    &-finish {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-grey {
        width: 100%;
        position: relative;
        max-width: 320px;
      }
      &-greyLine {
        width: 100%;
        position: absolute;
        margin-top: -569px;
        max-width: 300px;
        z-index: 2;
        margin-left: 6px;
      }
      &-greyRoute {
        width: 100%;
        position: absolute;
        margin-top: -577px;
        // max-width: 290px;
      }
    }
  }
  &__award {
    white-space: nowrap;
    margin-top: -50px;
  }
  &__goals {
    display: flex;
    flex-direction: row;
    width: 100%;
    &-begin {
      width: 36%;
      display: flex;
    }
  }
  &__desktop {
    @media (max-width: 699px) {
      display: none;
    }
    @media (min-width: 700px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 700px;
      position: relative;
      &_imgWrapp {
        max-width: 100%;
        max-height: 750px; // Set a fixed height or adjust as needed
        overflow: hidden;
      }
      &_mapImg {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      &-info {
        position: absolute;
        display: flex;
        flex-direction: column;
        left: 0;
        top: 44px;
      }
      &-infoEnd {
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 0;
        top: 44px;
      }
    }
  } 
}
