@import "../../assets/style/_var.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;
  box-sizing: border-box;
  height: 100px;
  transition: top 0.3s ease;
  // min-width: 100%;

  &-hidden {
    top: -70px;
  }

  .fadeInDown {
    opacity: none !important;
  }

  &__wrapper {
    display: flex;
    padding: 32px;
    padding-bottom: 100px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-image: linear-gradient(180deg,
        #ffff 34%,
        rgba(255, 255, 255, 0) 100%);

    @media (max-width: 575px) {
      padding: 22px;
      width: 100%;
      box-sizing: border-box;
    }

    &-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  &__logo-wrapper {
    mix-blend-mode: multiply;
  }

  &__logo {
    height: 55px;
    width: 220px;
    vertical-align: middle;

    @media (max-width: 575px) {
      display: none;
    }
  }

  &__logo-mob {
    display: none;

    @media (max-width: 430px) {
      display: block;
      height: 30px;
      width: 30px;
      vertical-align: middle;
    }
  }

  &__img-lang {
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 100%;
    position: relative;
    cursor: pointer;

    @media (max-width: 430px) {
      width: 30px;
      height: 30px;
    }
  }

  &__menu {
    display: flex;
    font-family: $baseFont;
    color: $black;
    align-items: center;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $black;
      border-radius: 100%;
      width: 36px;
      height: 36px;
      margin-right: 10px;

      @media (max-width: 430px) {
        width: 30px;
        height: 30px;

        svg {
          width: 15px;
          height: 20px;
        }
      }
    }

    &-col {
      display: flex;
      flex-direction: column;

      @media (max-width: 1000px) {
        display: none;
      }
    }

    &-title {
      font-size: 14px;
      font-weight: 500;
      color: #111927;
    }

    &-info {
      font-weight: 700;
      font-size: 18px;
    }

    &-item {
      font-weight: 700;
      font-size: 21px;

      @media (max-width: 430px) {
        font-size: 12px;
        margin-left: 10px;
      }
    }
  }

  &.header-hidden {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.header-hidden {
  opacity: 0;
  transform: translateY(-100%);
}

.animated {
  opacity: none !important;
}