body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  /* Ensure a minimum height of the viewport */
  display: flex;
  flex-direction: column;
}



ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

p {
  margin: 0;
}